.gradient-bg {
  background: radial-gradient(
    50% 50% at 69.5% 50%,
    #1600de 0%,
    rgb(4, 0, 38) 100%
  );
}

.presale-part {
  padding: 120px 20px 0px 20px;
  width: 1125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 60px;
}

.claim-part {
  background: #0e5162;
  flex-direction: column;
  padding: 60px 0;
  position: relative;
}

.claim-title {
  font-family: Garet;
  font-size: 48px;
  font-style: normal;
  font-weight: 850;
  line-height: 110%;
  margin-bottom: 20px;
  text-shadow: 0 2px 3px #0e4351;
}

.claim-description {
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
}

.claim-calculating-amount {
  border: 1px solid #8d0af4;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 0 240px 0 #8d0af42e;
  width: 540px;
  box-sizing: border-box;
}

.form-amount {
  margin-bottom: 30px;
}

.claim-box-img {
  height: 41px;
  width: 41px;
}

.spinner-border {
  animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  width: var(--bs-spinner-width);
}

.claim-btn {
  background: var(--button, linear-gradient(0deg, #8d0af4 0, #ef127c 123.44%));
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 3px 0 0 #ffffff4d, inset 0 -2px 10px 0 #fff6;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 51px;
  line-height: normal;
  text-align: center;
  transition: all 0.1s ease-in-out;
  width: 100%;
}

.cat-img {
  bottom: 0;
  height: auto;
  position: absolute;
  right: 0;
  width: 258px;
}

.dog-img {
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  width: 258px;
}

@media screen and (max-width: 1150px) {
  .dog-img {
    display: none;
  }
  .cat-img {
    display: none;
  }
  .claim-part span {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .presale-part {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 920px) {
  .claim-description {
    width: 370px;
    line-height: 20px;
  }
  .claim-calculating-amount {
    width: 440px;
  }
}

@media screen and (max-width: 500px) {
  .claim-calculating-amount {
    width: 388px;
  }
  .claim-description {
    margin-bottom: 20px;
  }
}

.presale-dashbaord {
  width: 100%;
  background: #0e4351;
}

.pattern {
  background-image: url('../../public/assets/img/pattern.svg');
  background-size: 1600px;
  background-position: left;
  background-repeat: repeat;
  animation: mymove 20s linear infinite;
  background-position: left;
  opacity: 0.05;
}

@keyframes mymove {
  50% {
    background-position: center;
  }
}
