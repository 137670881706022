@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Rajdhani;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: lightblue;
  height: 100vh;
  align-items: center;
}
.progress {
  width: 100px;
  height: 100px;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: #0e1d69;
  text-align: center;
  line-height: 100px;
  margin: 20px;
}
.progress::after {
  content: '%';
}
.progress .title {
  position: relative;
  z-index: 100;
}
.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #0e1d69;
}
.progress .left,
.progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 10px solid rgba(2, 153, 255);
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}
.animate10 {
  animation: loadn1 1s linear forwards;
}
.animate25 {
  animation: loadn2 1s linear forwards;
}
.animate70 {
  animation: loadn3 1s linear forwards;
}
.animate115 {
  animation: loadn4 1s linear forwards;
}
.animate120 {
  animation: loadn5 1s linear forwards;
}

.animate50 {
  animation: load1 1s linear forwards;
}
.animate100 {
  animation: load2 1s linear forwards;
}
.animate150 {
  animation: load3 1s linear forwards;
}
.animate200 {
  animation: load4 1s linear forwards;
}
.animate350 {
  animation: load5 1s linear forwards;
}

@keyframes loadn1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3.6deg);
  }
}
@keyframes loadn2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(9deg);
  }
}
@keyframes loadn3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(25.2deg);
  }
}
@keyframes loadn4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(41.4deg);
  }
}
@keyframes loadn5 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(43.2deg);
  }
}

@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(18deg);
  }
}
@keyframes load2 {
  0% {
    transform: rotate(18deg);
  }
  100% {
    transform: rotate(36deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(36deg);
  }
  100% {
    transform: rotate(54deg);
  }
}
@keyframes load4 {
  0% {
    transform: rotate(54deg);
  }
  100% {
    transform: rotate(72deg);
  }
}
@keyframes load5 {
  0% {
    transform: rotate(72deg);
  }
  100% {
    transform: rotate(136deg);
  }
}

.markStr {
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  --extracted-1w3ko1f: rgb(234, 187, 87);
  --extracted-3sq8v0: rgb(56, 166, 255);
  --extracted-r6o4lv: rgb(250, 250, 250);
  --framer-link-text-color: rgb(0, 153, 255);
  --framer-link-text-decoration: underline;
  transform: none;
  opacity: 1;
}
.makrStrp {
  --framer-font-family: 'Montserrat', 'Montserrat Placeholder', sans-serif;
  --framer-font-size: 23px;
  --framer-font-weight: 700;
  --framer-letter-spacing: 0px;
  --framer-text-color: var(--extracted-r6o4lv, rgb(250, 250, 250));
}

.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  border-bottom-width: 1px;
  border-color: rgba(48, 54, 171, 0.4);
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-top-width: 0px;
  backdrop-filter: blur(15px);
  background-color: rgba(0, 3, 84, 0.7);
  opacity: 1;
  z-index: 10;
}

@font-face {
  font-family: Rajdhani;
  font-weight: 300;
  src: url(./assets/fonts/Rajdhani-Light.ttf);
}

@font-face {
  font-family: Rajdhani;
  font-weight: 400;
  src: url(./assets/fonts/Rajdhani-Regular.ttf);
}

@font-face {
  font-family: Rajdhani;
  font-weight: 500;
  src: url(./assets/fonts/Rajdhani-Medium.ttf);
}

@font-face {
  font-family: Rajdhani;
  font-weight: 600;
  src: url(./assets/fonts/Rajdhani-SemiBold.ttf);
}

@font-face {
  font-family: Rajdhani;
  font-weight: 700;
  src: url(./assets/fonts/Rajdhani-Bold.ttf);
}

.navbar {
  width: 100%;
  --border-bottom-width: 1px;
  --border-color: rgba(48, 54, 171, 0.4);
  --border-left-width: 0px;
  --border-right-width: 0px;
  --border-style: solid;
  --border-top-width: 0px;
  backdrop-filter: blur(15px);
  background-color: rgba(0, 3, 84, 0.7);
  opacity: 1;
}

.wallet-adapter-button-trigger {
  background: rgb(0,102,255);
  /* background-color: #0398fc;
  border-radius: 12px;
  height: 36px; */
  /* background-color: transparent; */
  /* border: 1px solid; */
  padding: 0.5rem;
  border-radius: 0.375rem;
  width: 100%;
  height: 3.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  font-family: Rajdhani !important;
  @media (max-width: 900px) {
    height: 2.5rem;
  }
}

.wallet-adapter-button:not([disabled]):hover {
  /* background-color: #51afee; */
  background-color: #8cb0c7;
}
